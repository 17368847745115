import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import { modifyMdImageUrls } from "../../utils";

import ImageBlock from "../Blocks/ImageBlock/ImageBlock";

import "../../css/components/Page.scss";

const Page = ({ slug, pageTitle, pageContent, coverImg, imageHost }) => {
  const boxHeight = "650px";
  const boxHeightRectangle = "360px"; // eslint-disable-line no-unused-vars
  const pageContentWithImageUrlFixed = modifyMdImageUrls(pageContent, imageHost);

  return (
    <div>
      <Container fluid className="page-container">
        <Row>
          <Col md={12} lg={12} className="px-0">
            <ImageBlock headingText={pageTitle} hasBackArrow={true} textColor={"white"} backgroundImage={coverImg} boxHeight={boxHeight}></ImageBlock>
          </Col>
        </Row>
        {slug !== "kutatas-fejlesztes" && (
          <Col sm={12} md={6} lg={6} className="fixed-page-navigation">
            <ul className="no-list-style pt-4">
              <li>
                <Link to="/energy">ENERGIA</Link>
              </li>
              <li>
                <Link to="/smart-city">VÁROSFEJLESZTÉS</Link>
              </li>
              <li>
                <Link to="/smart-city">DIGITÁLIS TERMÉKEK</Link>
              </li>
            </ul>
          </Col>
        )}
        <Row className="row-2">
          <Col sm={12} md={12} lg={12} className={`p-0 page-header-mt ${slug !== "kutatas-fejlesztes" ? "page-header-with-nav" : ""}`}>
            <div className="content">
              <div>{<ReactMarkdown rehypePlugins={[rehypeRaw]} children={pageContentWithImageUrlFixed} />}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Page;
