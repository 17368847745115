import * as React from "react";
import { graphql } from "gatsby";

import Seo from "../components/seo";
import Header from "../components/Navbar/Header/Header";
import Footer from "../components/Footer/Footer";
import Contact from "../components/Contact/Contact";
import Page from "../components/Page/Page";
import EmptySlider from "../components/Sliders/EmptySlider/EmptySlider";

import "../css/pages/index.scss";

const PageTitle = ({ data }) => {
  const pageData = data.page;
  const imageHost = data.site.siteMetadata.imageHost;

  return (
    <>
      <Seo title={pageData.edges[0].node.title} description={pageData.edges[0].node.seo_description}></Seo>
      <Header />
      <main>
        <EmptySlider customClass="page-container-slider">
          {pageData &&
            pageData.edges.map((item, index) => (
              <Page
                key={item.node.id}
                slug={item.node.slug}
                pageTitle={item.node.title}
                pageContent={item.node.contents.data.contents}
                coverImg={item.node.cover.localFile.url}
                imageHost={imageHost}></Page>
            ))}
        </EmptySlider>

        <Contact oneMap={true}></Contact>
      </main>
      <Footer />
    </>
  );
};

export default PageTitle;

export const query = graphql`
  query PageTitle($slug: String!) {
    site: site {
      siteMetadata {
        imageHost
      }
    }
    page: allStrapiPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          slug
          contents {
            data {
              contents
            }
          }
          title
          seo_description
          cover {
            localFile {
              url
            }
          }
        }
      }
    }
  }
`;
